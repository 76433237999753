import styled, { css } from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* Desktop: three columns layout */
  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
    ${({ $col }) =>
      $col &&
      css`
        grid-template-columns: repeat(${$col}, 1fr);
      `}
  }

  gap: 8px;
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : '1138px')};
  margin: 0 auto;
  padding: 20px;
  padding-top: 50px;
  min-height: 100vh;
`;

export const GridItem = styled.div`
  &.span-three {
    grid-column: span 3;
  }
  &.span-two {
    grid-column: span 2;
  }
`;

export const FooterInfo = styled.div`
  font-family: 'Gilroy Medium';
  color: #000;
  margin-top: 56px;
  font-size: 16px;
  text-align: center;
  & > a {
    font-family: 'Gilroy SemiBold';
    color: #0099da;
    cursor: pointer;
  }
`;

export const ButtonGroup = styled(Flex)`
  gap: 16px;
  margin-top: 32px;
  width: 464px;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 32px;
  color: #000;
  font-family: 'Gilroy Bold';
  ${({ $bigTitle }) =>
    $bigTitle &&
    `
    font-size: 64px;
    line-height: 64px;
    & > span{
     color: #0099DA;
    }
  `}
`;

export const WhiteBackground = styled.div`
  background-color: white;
`;

export const ItemList = styled(Flex)`
  gap: 24px;
`;

export const Header = styled(Flex)`
  margin: 0 auto;
  max-width: 1497px;
  padding: 0 32px;
  height: 75px;
  border-bottom: 1px solid #ececed;
  margin-bottom: 48px;
`;

export const ItemTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  color: #14141f;
  font-family: 'Gilroy Medium';
`;

export const ItemDescription = styled.p`
  margin: 0;
  margin-top: 8px;
`;

export const IconButton = styled.div`
  background-color: #0099da;
  position: relative;
  display: flex;
  justify-content: center;
  color: #fff;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #0099da;
  font-size: 18px;
  font-family: 'Gilroy SemiBold';
  cursor: pointer;
  padding: 14px 50px;
  width: 100%;
  & > span {
    position: relative;
  }
  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      background-color: #fff;
      color: #0099da;
    `}
`;

export const Button = styled.button`
  background-color: #0099da;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #0099da;
  font-family: 'Gilroy SemiBold';
  cursor: pointer;
  padding: 14px 50px;
  width: 100%;
  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      background-color: #fff;
      color: #0099da;
    `}
`;

export const Info = styled.p`
  margin: 0;
  color: #000000;
  font-family: 'Gilroy Medium';
  font-size: 14px;
`;
export const FooterLink = styled.a`
  font-family: 'Gilroy SemiBold';
  color: #0099da;
  cursor: pointer;
`;
